<template>
  <div class="tablebody">
    <div class="info">
      <div class="paycount" id="paycount1">{{totalRows}}</div>
      <b-tooltip target="paycount1">Количество поставщиков</b-tooltip>
      <b-btn class='paycount' id="refrash" size="sm" @click="getUsers" ref="btnShow"> <b-icon-arrow-repeat> </b-icon-arrow-repeat> </b-btn>
      <b-tooltip target="refrash">Обновить</b-tooltip>
      <b-btn class='paycount' id="add" size="sm"  @click="info" ref="btnShow" v-if="(isOwner||isBank)&!isGuest"> <b-icon-plus> </b-icon-plus> </b-btn>
      <b-tooltip target="add">Добавить пользователя</b-tooltip>
    </div>
    <b-container fluid>
    <b-row>
      <b-col md="3" class="my-1">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Поиск" size="sm" style="background-color: #c6ced4" />
            <b-input-group-append>
              <b-btn size="sm" :disabled="!filter" @click="filter = ''"><b-icon-backspace></b-icon-backspace></b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="3" class="my-1">
        <b-input-group v-if="isOwner||isBank">
          <b-form-select v-model="dealer" :options="dealer_list" size="sm" style="background-color: #c6ced4" >
            <template slot="first">
              <option :value="null" >-- Дилер --</option>
            </template>
          </b-form-select>
          <b-btn size="sm" :disabled="!dealer" @click="dealer = null" ><b-icon-backspace></b-icon-backspace></b-btn>
        </b-input-group>
      </b-col>
      <b-col md="3" class="my-1" >
        <b-input-group v-if="isOwner||isBank">
          <b-form-select v-model="supplier" :options="supplier_list" size="sm" style="background-color: #c6ced4" >
            <template slot="first">
              <option :value="null" >-- Поставщик --</option>
            </template>
          </b-form-select>
          <b-btn size="sm" :disabled="!supplier" @click="supplier = null" ><b-icon-backspace></b-icon-backspace></b-btn>
        </b-input-group>
      </b-col>
    </b-row>
    <b-table striped hover small show-empty
              table-variant="dark"
              style="color: #ccc"
              stacked="md"
              :items="items"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              @filtered="onFiltered"
    >
    <template v-slot:cell(status)="data">
      <span v-html="data.item.status"></span>
    </template>
    <template v-slot:cell(actions)="data">
      <b-btn class='button' size="sm" @click="sendMail(data.item)" v-if="!data.item.disabled&!isGuest" style="margin: 0 4px 0 0"> <b-icon-envelope></b-icon-envelope> </b-btn>
      <b-btn class='button' size="sm" @click="updateUser(data.item,'disable', !data.item.disabled)" v-if="!data.item.disabled&isOwner&!isGuest" style="margin: 0 4px 0 0"> <b-icon-lock> </b-icon-lock> </b-btn>
      <b-btn class='button' size="sm" @click="updateUser(data.item,'enable', !data.item.disabled)" v-if="data.item.disabled&isOwner&!isGuest" style="margin: 0 4px 0 0"> <b-icon-unlock> </b-icon-unlock> </b-btn>
      <b-btn class='button' size="sm" @click="updateUser(data.item,'delete', true)" v-if="isOwner&!isGuest" style="margin: 0"> <b-icon-backspace></b-icon-backspace> </b-btn>
    </template>
    </b-table>
    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination pagination-sm :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
      </b-col>
    </b-row>
    <div>
      <b-modal id="userDetail"
                centered
                title="Пользователь"
                size= 'sm'
                buttonSize= 'sm'
                okVariant= 'success'
                @ok="addUser"
                :ok-disabled='!nameState||!emailState'
                auto-focus-button= 'ok'>
        <div role="group">
          <b-form-input
            id="input-live-name"
            v-model="name"
            :state="nameState"
            placeholder="ФИО"
            trim size="sm"
            autofocus
          ></b-form-input>
          <b-form-input
            id="input-live-email"
            v-model="email"
            :state="emailState"
            placeholder="Email"
            trim size="sm"
          ></b-form-input>
        </div>
        <b-input-group>
          <b-form-select v-model="role" :options="roles_list" size="sm" > </b-form-select>
        </b-input-group>
      </b-modal>
    </div>
  </b-container>
</div>
</template>

<script>
import axios from 'axios';
import config from '../_services/config.json';

export default {
  name: 'services',
  data: () =>  ({
    isBusy: false,
    items: [],
    fields: [
      //{ label: '#', key: 'index', 'class': 'text-center'},
      { label: 'ID', key: "id", 'class': 'text-left' },
      { label: 'Логин', key: "username", sortable: true, 'class': 'text-left' },
      { label: 'ФИО', key: "firstName", sortable: true, 'class': 'text-left' },
      { label: 'Роль', key: "role", 'class': 'text-left' },
      { label: 'Статус', key: "status", 'class': 'text-left' },
      { label: 'Действия', key: 'actions', 'class': 'text-right' }
    ],
    selected: 'false',
    options: [
      { text: 'Включенные', value: 'false' },
      { text: 'Выключенные', value: 'true' },
      { text: 'Удаленные', value: '' }
    ],
    supplier_list: [],
    dealer_list: [],
    roles_list: [],
    dealer: null,
    supplier: null,
    currentPage: 1,
    perPage: 40,
    totalRows: 0,
    activeServices: '',
    totalsum: 0,
    pageOptions: [ 20, 40, 80 ],
    sortBy: null,
    sortDesc: false,
    sortDirection: 'asc',
    filter: null,
    name: '',
    email: '',
    role: 2,
    infoModal: {
          id: 'userDetail'
        }
  }),
  computed: {
    isOwner () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Владелец'
      else return false
    },
    isBank () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Банк'
      else return false
    },
    isGuest () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.role==='guest'
      else return false
    },
    sortOptions () {
    // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => { return { text: f.label, value: f.key } })
    },
    nameState() {
      return this.name.length > 2 ? true : false
    },
    emailState() {
      //eslint-disable-next-line
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.email));
    }
  },
  methods: {
    info() {
        this.$root.$emit('bv::show::modal', 'userDetail', '#btnShow');
        this.email='';
        this.name='';
        this.role='operator';
    },
    handleOk() {
      alert('Ok');
    },
    reload () {
      this.getUsers ()
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    expandAdditionalInfo(row) {
      //row.toggleDetails
      row._showDetails = !row._showDetails;
    },
    getUsers () {
      axios
      .post(`${config.apiUrl}/query/getdata`,{DB: 'prodDB', partnerId: this.dealer||this.supplier||this.$store.state.authentication.user.partnerid, type: ['Users'],
                                              token: this.$store.state.authentication.user.token})
      .then(response => {
              this.items = response.data;
              this.totalRows = this.items.length;
            })
      .catch(error => {
        if (error.response.status === 401) {
            // auto logout if 401 response returned from api
            this.$store.dispatch('authentication/logout');
            this.$router.push('/')
        } else alert(error)
      });
    },
    addUser () {
      if (this.name!=''&&this.email!='') {
      axios
      .post(`${config.apiUrl}/query/getdata`,{DB: 'prodDB', partnerId: this.dealer||this.supplier||this.$store.state.authentication.user.partnerid,
                                              name: this.name, login: this.email, role: this.role, type: ['AddUser','AddUserRole'],
                                              token: this.$store.state.authentication.user.token})
      .then(
        this.getUsers()
      )
      .catch(error => {
        if (error.response.status === 401) {
            // auto logout if 401 response returned from api
            this.$store.dispatch('authentication/logout');
            this.$router.push('/')
        } else alert(error)
      });
      }
    },
    updateUser (user, action, state) {
      if (confirm("Вы уверены?"))
      axios
      .post(`${config.apiUrl}/query/getdata`,{DB: 'prodDB', partnerUserId: user.id, action: action, state: state, type: ['updateUser'], token: this.$store.state.authentication.user.token})
      .then(
        this.getUsers()
      )
      .catch(error => {
        if (error.response.status === 401) {
            // auto logout if 401 response returned from api
            this.$store.dispatch('authentication/logout');
            this.$router.push('/')
        } else alert(error)
      });
    },
    sendMail (user) {
      axios
      .post(`${config.apiUrl}/query/getdata`,{DB: 'prodDB', partnerUserId: user.id, type: ['sendMail'], token: this.$store.state.authentication.user.token})
      .catch(error => {
        if (error.response.status === 401) {
            // auto logout if 401 response returned from api
            this.$store.dispatch('authentication/logout');
            this.$router.push('/')
        } else alert (error)
      });
    },
    dealerSupplier () {
      if (this.isOwner) {
      axios
          .post(`${config.apiUrl}/query/getdata`, { DB: 'prodDB', dealerId: this.$store.state.authentication.user.partnerid,
                                                        partnertype: this.$store.state.authentication.user.partnertype,
                                                        bankId: '', type: ['Dealers','Suppliers','Roles'], status: false,
                                                        token: this.$store.state.authentication.user.token})
          .then(response => {
                  let i = 0;
                  this.dealer_list = response.data.filter(v => v.reqtype === 'Dealers');
                  for (i = 0; i < this.dealer_list.length; i++) {
                    this.dealer_list[i].value=this.dealer_list[i].id,
                    this.dealer_list[i].text=this.dealer_list[i].name;
                  }
                  this.supplier_list = response.data.filter(v => v.reqtype === 'Suppliers');
                  for (i = 0; i < this.supplier_list.length; i++) {
                    this.supplier_list[i].value=this.supplier_list[i].id,
                    this.supplier_list[i].text=this.supplier_list[i].name;
                  }
                  this.roles_list = response.data.filter(v => v.reqtype === 'Roles');
                  for (i = 0; i < this.roles_list.length; i++) {
                    this.roles_list[i].value=this.roles_list[i].name,
                    this.roles_list[i].text=this.roles_list[i].name;
                  }
              //console.log(response);
                })
          .catch(error => {
            if (error.response.status === 401) {
                // auto logout if 401 response returned from api
                this.$store.dispatch('authentication/logout');
                this.$router.push('/')
            } else alert (error)
          });
      }
    },
  },
  watch: {
    selected: function () {
      this.getUsers()
    },
    dealer: function () { if (this.dealer) (this.supplier=null) },
    supplier: function () { if (this.supplier) this.dealer=null}
  },
  created() {
    this.getUsers ()
  },
  mounted () {
    this.dealerSupplier();
  }
}
</script>
<style scoped>
.tablebody{
  background-color: #21252b;
  font-size: 14px;
}
.info {
  display: flex;
  padding: 10px;
  justify-content: center;

}
.paycount {
  display: block;
  width: auto;
  margin: 10px;
  padding: 5px 10px;
  text-align: center;
  background-color: #6c757d;
  color: orange;
  border-radius: 3px;
}
</style>
